import { usePage } from "@inertiajs/react";
import Footer from "@/components/nav/footer";
import BaseLayout from "./baseLayout";
import CollaboratorOffboardBanner from "../components/common/banners/collaboratorOffboardBanner";
import Nav from "../components/nav/nav";
interface AppLayoutProps {
    children: React.ReactNode;
    title: string;
    description?: string;
    image?: string;
}

export default function AppLayout({
    title,
    description,
    image,
    children,
}: AppLayoutProps) {
    const { auth, session } = usePage().props;
    return (
        <BaseLayout title={title} description={description} image={image}>
            {auth.user &&
                auth.user?.collaborator?.licenseIsExpiring &&
                !session.hideCollaboratorBanner && (
                    <CollaboratorOffboardBanner
                        collaborator={auth.user.collaborator}
                    />
                )}
            <Nav />
            <main className="bg-white" id="main-content">
                {children}
            </main>
            <Footer />
        </BaseLayout>
    );
}
